// layout
//================================================================================
html,body{
  height: 100%;
  position: relative;
}

body{
  font-family: 'Noto Sans TC', sans-serif;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

main{
  position: relative;
  margin-top: 104px;
  @media (max-width: 576px) {
    margin-top: 88px;
  }
}

footer{
  margin-top: auto;
  background-color: black;
}

*{
  line-height: 1.5;
  letter-spacing: 1.5px;
  transition: 0.5s;
  &::before,&::after{
    transition: 0.5s;
  }
}
p{
  @media (max-width: 375px) {
    font-size: 0.875rem;
  }
}

// 側邊導航欄
//================================================================================
.sidebar{
  position: absolute;
  z-index: 2;

  .sidebar-nav{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 20px;
    transform: translate(-50%,-50%);
    background-color: rgba(#000000,0.1);
    border-radius: 1rem;
  }

  .nav-item{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.25rem;
    border: solid 1px rgba(white,0.5);
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    @media (max-width: 992px) {
      width: 0.875rem;
      height: 0.875rem;
    }
    @media (max-width: 375px) {
      width: 0.5rem;
      height: 0.5rem;
    }

    .nav-link{
      padding: 0.25rem;
      box-sizing: border-box;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 100%; 
      &.active{
        background-color: $custom-yellow;
      }
    }
  }
}

// 滑鼠滾動
//================================================================================
.scroll{
  position: absolute;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  bottom: 3rem;
  transition: 1s;
  opacity: 1;

  &.disappear{
    opacity: 0;
    transition: 1s;
  }

  .scroll-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    &::after{
      position: relative;
      display: block;
      top: 1.5rem;
      left: -0.25rem;
      content:"scroll";
      color: rgba(white,0.5);
      transform: rotate(-90deg);
      font-weight: lighter;
      @media (max-width: 375px) {
        font-size: 0.875rem;
        left: -0.2rem;
      }
    }
  }

  .scroll-circle{
    border: solid 1px rgba(white,0.5);
    border-radius: 50rem;
    width: 40px;
    height: 60px;
    @media (max-width: 375px) {
      width: 30px;
      height: 45px;
    }

    &::before{
      content: "";
      display: block;
      width: 1px;
      height: 80px;
      background-color: rgba(white,0.5);
      margin: 0 auto;
      position: relative;
      animation: scroll-line 2.5s ease-in-out infinite;
      @keyframes scroll-line {
        0%{
          opacity: 0;
          height: 80px;
          transform: translateY(-80px);
        }
        100%{
          opacity: 1;
          height: 0px;
          transform: translateY(40px);
        }
      }
    }
  }
}

.overlay{
  background-color: rgba(black,0.5);
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 201;
  transform: translateX(100%);
  opacity: 0;

  &.show{
    animation: show-ani 0.5s forwards;
    @keyframes show-ani {
      0%{
        display: block;
        opacity: 0;
        transform: translateX(100%);
      }
      100%{
        display: block;
        transform: translateX(0%);
        opacity: 1;
      }
    }
  }
}

// 導覽列
//================================================================================
.navbar{
  height: 96px;
  @media (max-width: 576px) {
    height: 80px;
  }

  &::before{
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    top: 100%;
    height: 8px;
    background: linear-gradient(to left,#7F5225,#DBCA76);
  }

  .logo-bg{
    justify-content: start;
    position: absolute;
    width: 40%;
    height: 100%;
    left: 0;
    background-color: $custom-white;
    transform: skewX(30deg);
    @media (max-width: 991px) {
      background-color: transparent;
    }

    &::before{
      content: "";
      display: block;
      background-color: $custom-white;
      height: 100%;
      width: 50px;
      transform: skewX(-30deg);
      @media (max-width: 991px) {
        background-color: $custom-light;
      }
    }

    .logo{
      display: block;
      position: absolute;
      background-image: url(../images/logo.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      object-fit: contain;
      width: 280px;
      height: 80px;
      text-indent: 101%;
      white-space: nowrap;
      overflow: hidden;
      top: 10px;
      left: 10%;
      transform: skewX(-30deg);
      @media (max-width: 1200px) {
        width: 250px;
      }
      @media (max-width: 991px) {
        top: 18px;
        left: 20px;
        width: 220px;
        height: 65px;
      }
      @media (max-width: 768px) {
        width: 220px;
      }
      @media (max-width: 576px) {
        top: 8px;
      }
    }
  }

  .navbar-toggler{
    border-radius: 100%;
    border: solid 1px #B5B6B6;
    background-color: #F5F5F5;
  }

  .navbar-toggler-icon {
    width: 1.5rem;
    height: 2.5rem;
  }

  .collapse{
    height: 100%;
    &.show{
      animation: show-ani 0.5s forwards;
      @keyframes show-ani {
        0%{
          opacity: 0;
          transform: translateX(100%);
        }
        100%{
          transform: translateX(0%);
          opacity: 1;
        }
      }
    }
  }

  .collapse:not(.show){
    display: flex;
    opacity: 1;
    @media (max-width: 767px) {
      animation: disappear-ani 0.5s forwards;
      @keyframes disappear-ani {
        0%{
          margin-top: 4px;
          display: flex;
          transform: translateX(0%);
          opacity: 1;
        }
        100%{
          margin-top: 4px;
          display: flex;
          transform: translateX(-100%);
          opacity: 0;
        }
      }
    }
  }

  .navbar-nav{
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    @media (max-width: 767px) {
      margin-top: 8px;
      width: 100%;
    }

    .nav-item{
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      @media (max-width: 767px) {
        justify-content: center;
      }

      .nav-link{
        position: relative;
        display: flex;
        height: 100%;
        align-items: center;
        padding: 0rem 1rem;
        border-left: solid 1px white;
        white-space: nowrap;
        @media (max-width: 1200px) {
          padding: 0rem 0.5rem;
        }
        @media (max-width: 767px) {
          border-left: none;
          border-bottom: solid 1px white;
          width: 100%;
          height: 60px;
          background-color: $custom-light;
          &:hover{
            background: linear-gradient(#FFFFFF,#F5F5F5);
          }
        }
        &:hover{
          background-color: #fff;
        }
        &:last-child{
          border-right: solid 1px white;
          @media (max-width: 768px) {
            border-right: none;
          }
        }
      }
    }
  }
}


// banner橫幅
//================================================================================
.banner{
  position: relative;
  display: flex;
  align-items: center;

  &::before{
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(../images/img-banner-bg.png);
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;
    z-index: -1;
    animation: light-ani 5s linear infinite ;
    @media (max-width: 1200px) {
      background-position: 80% center;
    }
    @media (max-width: 991px) {
      background-position: center center;
    }
    @keyframes light-ani {
      0%{
        filter: brightness(0.9);
      }
      33%{
        filter: brightness(1.2);
      }
      66%{
        filter: brightness(1);
      }
      100%{
        filter: brightness(0.9);
      }
    }
  }

  .title-en{
    font-family: "Ramabhadra", serif;
    position: relative;
    display: block;
    color: rgba(#ffffff,0.1);
    font-size: 5rem;
    @media (max-width: 1200px) {
      font-size: 4rem;
    }
    @media (max-width: 768px) {
      font-size: 3rem;
    }
  }

  .title{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    @media (max-width: 576px) {
      font-size: 2.5rem;
    }
    @media (max-width: 375px) {
      font-size: 2rem;
    }
    @media (max-width: 320px) {
      font-size: 1.5rem;
    }

    &::before{
      content: "KING SHIELD";
      display: block;
      font-family: "Ramabhadra", serif;
      font-size: 5rem;
      color: rgba(#FFFFFF,0.1);
      white-space: nowrap;
      @media (max-width: 1200px) {
        font-size: 4rem;
      }
      @media (max-width: 768px) {
        font-size: 3rem;
      }
      @media (max-width: 375px) {
        font-size: 2.5rem;
      }
      @media (max-width: 320px) {
        font-size: 2rem;
      }
    }
  }

  .subtitle{
    color: white;
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 1rem;
    @media (max-width: 768px) {
      font-size: 1.25rem;
    }
    @media (max-width: 320px) {
      font-size: 1rem;
    }
  }

  .line{
    width: 80px;
    height: 4px;
    background-color: $custom-orange;
    @media (max-width: 576px) {
      margin: 0 auto;
    }
  }

  .banner-nav{
    display: flex;
    list-style-type: none;
    @media (max-width: 576px) {
      justify-content: center;
    }

    .banner-item{
      aspect-ratio: 1 / 1;
      margin-right: 0.5rem;
      @media (max-width: 576px) {
        margin-right: 0.25rem;
        margin-left: 0.25rem;
      }
      .banner-img{
        width: 80px;
        @media (max-width: 576px) {
          width: 60px;
        }
      }
    }
  }
}

// 關於我們
//================================================================================
.about{
  position: relative;
  color: white;
  background-color: black;
  padding: 10rem 0rem 20rem 0rem;
  z-index: 1;
  @media (max-width: 991px) {
    padding: 6rem 0rem 18rem 0rem;
  }
  @media (max-width: 375px) {
    padding: 5rem 0rem 15rem 0rem;
  }

  &::before{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(../images/img-about-bg.png);
    background-position: center bottom;
    background-repeat: repeat-x;
    z-index: -1;
  }

  .left-arrow{
    position: relative;
    top: -0.875rem;
    width: 100px;
    margin-right: 2.5rem;
    @media (max-width: 768px) {
      top: -0.5rem;
    } 
    @media (max-width: 375px) {
      width: 60px;
    }
    img{
      aspect-ratio: 61 / 16;
    } 
  }

  .right-arrow{
    position: relative;
    top: -0.875rem;
    width: 100px;
    margin-left: 2.5rem;
    @media (max-width: 768px) {
      top: -0.5rem;
    } 
    @media (max-width: 375px) {
      width: 60px;
    }
    img{
      aspect-ratio: 61 / 16;
    }
  }

  .title{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    flex-shrink: 0;
    @media (max-width: 768px) {
      margin-bottom: 1rem;
      font-size: 2rem;
    }
    @media (max-width: 576px) {
      font-size: 1.875rem;
    }
    @media (max-width: 375px) {
      font-size: 1.5rem;
    }

    &::before{
      position: absolute;
      top: -100%;
      left: 50%;
      transform: translate(-50%,-50%);
      content: "ABOUT US";
      display: block;
      font-family: "Ramabhadra", serif;
      font-size: 5rem;
      color: rgba(#FFFFFF,0.1);
      white-space: nowrap;
      @media (max-width: 1200px) {
        font-size: 4rem;
      }
      @media (max-width: 768px) {
        font-size: 3rem;
      }
      @media (max-width: 375px) {
        font-size: 2.5rem;
      }
    }
  } 

  .line{
    margin: 3rem auto;
    width: 1px;
    height: 80px;
    background-color: white;
    @media (max-width: 375px) {
      height: 50px;
    }
  }
  .bottom-text{
    font-size: 1.125rem;
    color: $custom-yellow;
    @media (max-width: 375px) {
      font-size: 1rem;
    }
    @media (max-width: 320px) {
      font-size: 0.875rem;
    }
  }
}

// 公司歷程
//================================================================================
.history{
  position: relative;
  height: 400px;
  background: linear-gradient(#FFFFFF,#EEEEEE);
  box-shadow: 0px 4px 16px rgba(#000000,0.1);
  z-index: 200;
  @media (max-width: 768px) {
    height: 320px;
  }

  &::before{
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 8px;
    background: linear-gradient(to left,#7F5225,#DBCA76);
    z-index: 99;
  }

  .swiper-history{
    padding: 0rem 5rem;
    width: 100%;
    height: 100%;
    @media (max-width: 768px) {
      padding: 0rem 3rem;
    }
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    border-left: dashed 1px #CCCCCC;
  }

  .swiper-item{
    margin-left: 1rem;
    margin-bottom: 3rem;
    margin-top: 5rem;
    @media (max-width: 768px) {
      margin-bottom: 2rem;
      margin-top: 3rem;
    }
  }

  .swiper-year{
    font-size: 2rem;
    font-weight: bold;
    @media (max-width: 991px) {
      font-size: 1.875rem;
    }
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
    &::before{
      position: relative;
      top: 8px;
      content: "";
      display: inline-block;
      width: 4px;
      height: 40px;
      margin-right: 1rem;
      background-color: $custom-brown;
      @media (max-width: 768px) {
        margin-right: 0.5rem;
      }
    }
  }

  .swiper-list{
    margin-top: 1.5rem;
    margin-left: 2rem;
    list-style: "- ";
    font-size: 1.125rem;
    @media (max-width: 991px) {
      margin-left: 1.5rem;
      font-size: 1rem;
    }
    @media (max-width: 768px) {
      margin-left: 1rem;
      font-size: 0.875rem;
    }
  }

  .swiper-button-next,.swiper-button-prev{
    &::after{
      content: "";
    }
    img{
      aspect-ratio: 20 / 49;
    }
  }
}

// 技術應用
//================================================================================
.technology{
  padding: 10rem 0rem;
  background-image: url(../images/img-technology-bg.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @media (max-width: 991px) {
    padding: 6rem 0rem;
  }
  @media (max-width: 375px) {
    padding: 3rem 0rem;
  }

  .title{
    position: relative;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    @media (max-width: 768px) {
      margin-bottom: 1rem;
      font-size: 2rem;
    }
    @media (max-width: 576px) {
      font-size: 1.875rem;
    }

    &::before{
      content: "TECHNOLOGY";
      display: block;
      font-family: "Ramabhadra", serif;
      font-size: 5rem;
      color: rgba(#000000,0.1);
      white-space: nowrap;
      @media (max-width: 1200px) {
        font-size: 4rem;
      }
      @media (max-width: 768px) {
        font-size: 3rem;
      }
      @media (max-width: 375px) {
        font-size: 2.5rem;
      }
    }
  }

  .line{
    width: 80px;
    height: 4px;
    background-color: $custom-orange;
  }

  .card-img{
    flex: 2;
    @media (max-width: 1200px) {
      flex: 1;
    }
    img{
      aspect-ratio: 16 / 9;
    }
  }

  .card-text{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 1rem;
  }

  .card-title{
    font-size: 1.25rem;
    font-weight: bold;
    color: $custom-orange;
    margin-bottom: 1rem;
    @media (max-width: 576px) {
      margin-bottom: 0.25rem;
    }
  }

  .card-arrow{
    margin-top: 1rem;
    img{
      aspect-ratio: 63 / 16;
    }
  }
}

// 智慧生活
//================================================================================
.better{
  padding: 15rem 0rem;
  width: 100%;
  background-image: url(../images/img-better-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-attachment: fixed;
  @media (max-width: 768px) {
    padding: 10rem 0rem;
  }

  &::before{
    position: absolute;
    content: "";
    display: block;
    width: 80%;
    height: 80%;
    background-image: url(../images/img-shield.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    mix-blend-mode: lighten;
    @media (max-width: 768px) {
      width: 80%;
      height: 80%;
    }
    @media (max-width: 320px) {
      width: 60%;
      height: 60%;
    }
  }

  &::after{
    position: absolute;
    content: "";
    display: block;
    background-image: url(../images/logo2.svg);
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 1rem;
    width: 150px;
    height: 150px;
    background-color: #fff;
    border-radius: 100%;
    border: solid 3px #D1AE54;
    transform: translateY(-15px);
    @media (max-width: 768px) {
      width: 120px;
      height: 120px;
    }
    @media (max-width: 576px) {
      width: 100px;
      height: 100px;
    }
    @media (max-width: 320px) {
      width: 80px;
      height: 80px;
    }
  }

  .slogan{
    display: flex;
    color: white;
    font-size: 2rem;
    font-weight: bold;
    @media (max-width: 768px) {
      font-size: 1.25rem;
    }
    @media (max-width: 576px) {
      font-size: 1rem;
    }
    @media (max-width: 576px) {
      font-size: 0.875rem;
    }
    .left{
      margin-right: 10rem;
      @media (max-width: 768px) {
        margin-right: 6rem;
      }
      @media (max-width: 576px) {
        margin-right: 4rem;
      }
      @media (max-width: 320px) {
        margin-right: 3rem;
      }
    }
    .right{
      margin-left: 10rem;
      @media (max-width: 768px) {
        margin-left: 6rem;
      }
      @media (max-width: 576px) {
        margin-left: 4rem;
      }
      @media (max-width: 320px) {
        margin-left: 3rem;
      }
    }
  }

  .slogan-en{
    font-size: 1.5rem;
    font-weight: bold;
    color: $custom-yellow;
    transform: translateY(160px);
    @media (max-width: 768px) {
      font-size: 1.25rem;
      transform: translateY(7rem);
    }
    @media (max-width: 576px) {
      font-size: 1rem;
    }
    @media (max-width: 375px) {
      letter-spacing: 0px;
      font-size: 0.875rem;
    }
  }
}

// 系統整合
//================================================================================
.system{
  position: relative;
  padding: 10rem 0rem;
  background-image: url(../images/img-system-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
  @media (max-width: 991px) {
    padding: 6rem 0rem;
  }
  @media (max-width: 375px) {
    padding: 3rem 0rem;
  }

  .title{
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    @media (max-width: 768px) {
      margin-bottom: 1rem;
      font-size: 2rem;
    }
    @media (max-width: 576px) {
      font-size: 1.875rem;
    }

    &::before{
      content: "SYSTEM";
      display: block;
      font-family: "Ramabhadra", serif;
      font-size: 5rem;
      color: rgba(#000000,0.1);
      white-space: nowrap;
      @media (max-width: 1200px) {
        font-size: 4rem;
      }
      @media (max-width: 768px) {
        font-size: 3rem;
      }
      @media (max-width: 375px) {
        font-size: 2.5rem;
      }
    }
  }

  .line{
    width: 80px;
    height: 4px;
    background-color: $custom-orange;
    margin: 0 auto;
  }

  .system-card{
    position: relative;
    width: 100%;
    height: 100%;
    border: solid 3px white;
    box-shadow: 0 4px 16px rgba(black , 0.25);
    background: linear-gradient(#FFFFFF,#EEEEEE);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(10px);

    &::before{
      position: absolute;
      content: "";
      display: block;
      right: 0;
      bottom: 0;
      width: 50%;
      height: 50%;
      background-image: url(../images/img-system-card-bg.png);
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .system-content{
    transform: translateY(-6rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    @media (max-width: 1200px) {
      margin: 0.5rem;
    }
    @media (max-width: 991px) {
      transform: translateY(0rem);
      flex-direction: row;
    }
  }

  .system-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    .system-img{
      img{
        aspect-ratio: 4 / 3;
      }
    }
  }

  .system-title{
    font-weight: bold;
    font-size: 1.25rem;
    text-align: center;
    width: 90%;
    padding: 0.5rem 1.5rem;
    background-color: $custom-orange;
    border-radius: 0.5rem;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
    @media (max-width: 576px) {
      padding: 0.25rem 1rem;
    }
  }

  .system-list{
    width: 100%;
    padding: 1rem;
    @media (max-width: 768px) {
      font-size: 0.875rem;
      padding: 0.5rem;
    }
    @media (max-width: 576px) {
      padding: 1.5rem;
    }

    li{
      box-sizing: border-box;
      padding-left: 1rem;
      margin-bottom: 0.5rem;

      &::before{
        content: "";
        display: inline-block;
        position: relative;
        top: -0.15rem;
        left: -0.35rem;
        width: 0.5rem;
        height: 0.5rem;
        background-color: $custom-orange;
      }
      @media (max-width: 1200px) {
        padding-left: 0.5rem;
      }
    }
  }
}

// 產品介紹
//================================================================================
.products{
  position: relative;
  padding: 10rem 0rem;
  background-image: url(../images/img-products-bg.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @media (max-width: 991px) {
    padding: 6rem 0rem;
  }
  @media (max-width: 375px) {
    padding: 3rem 0rem;
  }

  .title{
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    @media (max-width: 768px) {
      margin-bottom: 1rem;
      font-size: 2rem;
    }
    @media (max-width: 576px) {
      font-size: 1.875rem;
    }

    &::before{
      content: "PRODUCTS";
      display: block;
      font-family: "Ramabhadra", serif;
      font-size: 5rem;
      color: rgba(#000000,0.1);
      white-space: nowrap;
      @media (max-width: 1200px) {
        font-size: 4rem;
      }
      @media (max-width: 768px) {
        font-size: 3rem;
      }
      @media (max-width: 375px) {
        font-size: 2.5rem;
      }
    }
  }

  .line{
    width: 80px;
    height: 4px;
    background-color: $custom-orange;
    margin: 0 auto;
  }

  .swiper-products{
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  .swiper-slide{
    padding: 3rem;
    @media (max-width: 768px) {
      padding: 2rem;
    }
    @media (max-width: 576px) {
      padding: 0rem;
    }
  }
  .swiper-button-prev{
    transform: translateX(-60px);
    padding: 2rem;
    border-radius: 100%;
    border: solid 1px #CCCCCC;
    background-color: #ffffff;
    &::after{
      content: "\f053";
      display: block;
      font-family: "Font Awesome 5 Free";
      font-size: 24px;
      font-weight: 900;
      color: #CCCCCC;
      @media (max-width: 375px) {
        font-size: 1rem;
      }
    }
    @media (max-width: 1400px) {
      transform: translateX(-40px) translateY(-100px);
    }
    @media (max-width: 992px) {
      transform: translateX(-40px) translateY(-240px);
    }
    @media (max-width: 768px) {
      padding: 1.5rem;
      transform: translateX(0px) translateY(-220px);
    }
    @media (max-width: 375px) {
      padding: 1rem 1.25rem;
      transform: translateX(0px) translateY(-240px);
    }
    @media (max-width: 320px) {
      transform: translateX(0px) translateY(-300px);
    }
  }
  .swiper-button-next{
    transform: translateX(60px);
    padding: 2rem;
    border-radius: 100%;
    border: solid 1px #CCCCCC;
    background-color: #ffffff;
    &::after{
      content: "\f054";
      display: block;
      font-family: "Font Awesome 5 Free";
      font-size: 24px;
      font-weight: 900;
      color: #CCCCCC;
      @media (max-width: 375px) {
        font-size: 1rem;
      }
    }
    @media (max-width: 1400px) {
      transform: translateX(40px) translateY(-100px);
    }
    @media (max-width: 992px) {
      transform: translateX(40px) translateY(-240px);
    }
    @media (max-width: 768px) {
      padding: 1.5rem;
      transform: translateX(0px) translateY(-220px);
    }
    @media (max-width: 375px) {
      padding: 1rem 1.25rem;
      transform: translateX(0px) translateY(-240px);
    }
    @media (max-width: 320px) {
      transform: translateX(0px) translateY(-300px);
    }
  }
  .products-item{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  .products-title-wrap{
    border-left: solid 6px $custom-orange;
    padding-left: 1rem;
  }

  .products-title-en{
    color: $custom-orange;
    @media (max-width: 768px) {
      font-size: 0.875rem;
    }
  }

  .products-title-zh{
    font-size: 2.25rem;
    font-weight: bold;
    @media (max-width: 991px) {
      font-size: 2rem;
    }
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  .products-description{
    margin-top: 2rem;
    line-height: 1.5;
    @media (max-width: 768px) {
      margin-top: 1rem;
      font-size: 0.875rem;
    }
  }

  .products-text-content{
    margin-top: 3rem;
    @media (max-width: 768px) {
      margin-top: 1rem;
    }

    h5{
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }
  }

  .products-list{

    li{
      box-sizing: border-box;
      padding-left: 0.25rem;
      margin-bottom: 0.5rem;
      &::before{
        content: "";
        display: inline-block;
        position: relative;
        top: -0.15rem;
        left: -0.35rem;
        width: 0.5rem;
        height: 0.5rem;
        background-color: $custom-orange;
      }
      @media (max-width: 1200px) {
        padding-left: 0.5rem;
      }
      @media (max-width: 768px) {
        font-size: 0.875rem;
      }
    }
  }
}

// E管+行動APP
//================================================================================
.application{
  position: relative;
  padding: 10rem 0rem;
  background-image: url(../images/img-application-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  z-index: 1;
  @media (max-width: 992px) {
    padding: 6rem 0rem;
  }
  @media (max-width: 375px) {
    padding: 3rem 0rem;
  }

  .text-content{
    position: relative;
    z-index: 2;
  }

  .title{
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    @media (max-width: 768px) {
      margin-bottom: 1rem;
      font-size: 2rem;
    }
    @media (max-width: 576px) {
      font-size: 1.875rem;
    }

    &::before{
      content: "APPLICATION";
      display: block;
      font-family: "Ramabhadra", serif;
      font-size: 5rem;
      color: rgba(#000000,0.1);
      white-space: nowrap;
      @media (max-width: 1200px) {
        font-size: 4rem;
      }
      @media (max-width: 768px) {
        font-size: 3rem;
      }
      @media (max-width: 375px) {
        font-size: 2.5rem;
      }
    }
  }

  .line{
    width: 80px;
    height: 4px;
    background-color: $custom-orange;
  }

  .application-list{

    .col{
      flex: 0 0;
    }
  }

  .application-icon{
    width: 80px;
    @media (max-width: 991px) {
      width: 60px;
    }
    @media (max-width: 576px) {
      width: 40px;
    }
    img{
      aspect-ratio: 1 / 1;
    }
  }

  .feature{
    font-size: 1.25rem;
    font-weight: bold;
  }

  .feature-list{

    li{
      box-sizing: border-box;
      padding-left: 0.25rem;
      margin-bottom: 0.5rem;

      &::before{
        content: "";
        display: inline-block;
        position: relative;
        top: -0.15rem;
        left: -0.35rem;
        width: 0.5rem;
        height: 0.5rem;
        background-color: $custom-orange;
      }
      @media (max-width: 1200px) {
        padding-left: 0.5rem;
      }
      @media (max-width: 768px) {
        font-size: 0.875rem;
      }
    }
  }

  .format{
    font-size: 1.25rem;
    font-weight: bold;
  }

  .format-list{

    li{
      box-sizing: border-box;
      padding-left: 0.25rem;
      margin-bottom: 0.5rem;
      line-height: 2;

      &::before{
        content: "";
        display: inline-block;
        position: relative;
        top: -0.15rem;
        left: -0.35rem;
        width: 0.5rem;
        height: 0.5rem;
        background-color: $custom-orange;
      }
      @media (max-width: 1200px) {
        padding-left: 0.5rem;
      }
      @media (max-width: 768px) {
        font-size: 0.875rem;
      }
    }
  }
  .img-call{
    padding-top: 0rem;
    width: 240px;
    height: 100px;
    transform: translateY(-20px);
    @media (max-width: 992px) {
      padding-top: 1rem;
      width: 220px;
    }
    @media (max-width: 768px) {
      width: 220px;
    }
    @media (max-width: 576px) {
      display: none;
    }
    @media (max-width: 375px) {

      width: auto;
    }
  }

  .holding-phone-bg{
    display: flex;
    justify-content: center;
    
    &::before{
      content: "";
      position: absolute;
      width: 90rem;
      height: 90rem;
      border-radius: 100%;
      top: -10%;
      margin-left: 50rem;
      background-image: url(../images/img-holding-phone-bg.png);
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 1;
      @media (max-width: 991px) {
        filter: opacity(0.5);
        border-radius: 0%;
      }
    }
  }

  .holding-phone{
    position: absolute;
    transform: translateX(8rem) translateY(6px);
    width: 500px;
    bottom: 0;
    z-index: 2;
    @media (max-width: 1200px) {
      transform: translateX(0rem) translateY(6px);
    }
    @media (max-width: 991px) {
      display: none;
    }
  }
}

// 聯絡我們
//================================================================================
.contact{
  position: relative;
  padding: 10rem 0rem;
  background-image: url(../images/img-footer-bg.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
  @media (max-width: 991px) {
    padding: 6rem 0rem;
  }
  @media (max-width: 375px) {
    padding: 3rem 0rem;
  }

  &::after{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(black,0.3);
    z-index: -1;
  }

  .gotop{
    position: absolute;
    top: 10%;
    right: 30px;
    width: 50px;
    height: 50px;
    border: solid 1px white;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(#ffffff,0.3);
    opacity: 0;
    @media (max-width: 375px) {
      width: 40px;
      height: 40px;
    }

    &:hover{
      background-color: $custom-orange;
      cursor: pointer;
    }

    i{
      color: white;
      font-size: 1.5rem;
    }

    &.show{
      animation: fade-ani 1s forwards;
      @keyframes fade-ani {
        0%{
          opacity: 0;
          transform: translateY(50px);
        }
        100%{
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
  }

  .title{
    color: white;
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 0px 0px 5px rgba(black,0.5);
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  .number{
    font-family: "Ramabhadra", serif;
    font-weight: lighter;
    font-size: 3rem;
    text-shadow: 0px 0px 10px rgba(black,0.3);
    @media (max-width: 768px) {
      font-size: 2.5rem;
    }
    @media (max-width: 375px) {
      font-size: 2rem;
    }
  }

  .address{
    font-size: 1.5rem;
    font-weight: 300;
    text-shadow: 0px 0px 5px rgba(black,0.5);
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
}

// 頁尾
//================================================================================
.copyright{
  font-weight: 300;
  line-height: 1.5;
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
}